import { Dispatch } from 'react';

import { isEqual } from 'lodash';

import {
    CORE_PIVOT_TABLE_SETTING_PROPERTY_KEYS,
    HIDDEN_PIVOT_ANOMALY_ATTRIBUTES,
    PivotViewOptions,
} from 'lib/constants';
import { PivotData } from 'lib/types/inspections/pivotTable';

import { makeRenderer } from './pivotTableRenderer';

export const countAggregator = () => () => ({
    count: 0,
    push: function push() {
        this.count++;
    },
    value: function value() {
        return this.count;
    },

    format: function format(value: number) {
        return value;
    },
});

export const percentageAggregator = (totalModules: number) => () => () => ({
    count: 0,
    push() {
        this.count++;
    },
    value() {
        return this.count;
    },
    format(value: number) {
        if (value === 0) {
            return null;
        } else {
            const pecentage = ((value * 100) / totalModules).toFixed(2);

            return pecentage === '0.00' ? `<0.01%` : `${pecentage}%`;
        }
    },
});

export const powerLossSumAggregator = () => () => ({
    sum: 0,
    push(anomaly: any) {
        if (!isNaN(parseFloat(anomaly['Power Loss']))) {
            this.sum += parseFloat(anomaly['Power Loss']);
        }
    },
    value() {
        return this.sum;
    },
    format(value: number) {
        return value === 0 ? null : `${(value / 1000).toFixed(2)} kW`;
    },
});

export const powerLossPercentageAggregator = (totalPowerLoss: number) => () => () => ({
    sum: 0,
    push(anomaly: any) {
        if (!isNaN(parseFloat(anomaly['Power Loss']))) {
            this.sum += parseFloat(anomaly['Power Loss']);
        }
    },
    value() {
        return this.sum;
    },
    format(value: number) {
        if (value === 0) {
            return null;
        } else {
            const pecentage = ((value * 100) / totalPowerLoss).toFixed(2);

            return pecentage === '0.00' ? `<0.01%` : `${pecentage}%`;
        }
    },
});

export const buildValueFilter = (values: any, filterValue?: any) =>
    values?.reduce((o: any, key: string) => (key === filterValue ? o : { ...o, [key]: true }), {});

export const negateValueFilter = (data: any, valueFilter?: any) =>
    Object.keys(data[0])
        .filter((column: string) => !HIDDEN_PIVOT_ANOMALY_ATTRIBUTES.includes(column))
        .reduce((prev: any, column: string) => {
            const uniqueValues = Array.from(new Set(data.map((row: any) => row[column])));

            if (valueFilter && valueFilter[column]) {
                const filterValues = uniqueValues.filter((value: any) => !valueFilter[column][value]);

                prev.push({ id: column, value: filterValues });
            }

            return prev;
        }, []);

export const buildDefaultPivotTableSettings = (
    activeModules: number,
    siteCapacityMW: number,
    setPivotDownloadData: Dispatch<any>,
) => {
    const sitePercentageAggregator = percentageAggregator(activeModules);
    const sitePowerLossPercentageAggregator = powerLossPercentageAggregator(siteCapacityMW * 1000000);

    return {
        aggregators: {
            Modules: countAggregator,
            Percentage: sitePercentageAggregator,
            PowerLossSum: powerLossSumAggregator,
            PowerLossPercentage: sitePowerLossPercentageAggregator,
        },
        unusedOrientationCutoff: Infinity,
        aggregatorName: 'Modules',
        rendererName: 'Table',
        renderers: {
            'Table': makeRenderer({ setPivotDownloadData }),
            'Table Heatmap': makeRenderer({
                heatmapMode: 'full',
                setPivotDownloadData,
            }),
        },
        hiddenFromDragDrop: HIDDEN_PIVOT_ANOMALY_ATTRIBUTES,
        rowOrder: 'key_a_to_z',
        colOrder: 'key_a_to_z',
    };
};

export const checkSettingsEqual = (pivotSettings: any, viewSettings: any) => {
    const corePivotSettings = CORE_PIVOT_TABLE_SETTING_PROPERTY_KEYS.map((key) => pivotSettings[key]);
    const coreViewSettings = CORE_PIVOT_TABLE_SETTING_PROPERTY_KEYS.map((key) => viewSettings[key]);

    return isEqual(corePivotSettings, coreViewSettings);
};

export const findMatchingPivotSettings = (settings: any, viewSettings: any) =>
    Object.keys(viewSettings)
        .filter((key) => key !== PivotViewOptions.CUSTOM)
        .find((view) => checkSettingsEqual(settings, viewSettings[view]));

/**
 * - `columnDimensions` => ['RGB Signal']
 * - `rowDimensions` => ['Priority']
 * - `colKeys` => [["Broken Module"],["Flagged Module"],["No Module"],["No RGB Signal"]]
 * - `rowKeys` => [['1 - Remediation Recommended']]
 */
export const buildCSVExport = (pivotData: PivotData, rowKeys: Array<string[]>, colKeys: Array<string[]>) => {
    const csvRows: string[][] = [];
    const { cols: columnDimensions, rows: rowDimensions } = pivotData.props;
    const emptyCell = '';

    const createEmptyCells = (count: number): string[] => Array(count).fill(emptyCell);

    const addColumnHeaders = () => {
        for (let colIndex = 0; colIndex < columnDimensions.length; colIndex++) {
            const headerRow = rowDimensions.length > 1 ? createEmptyCells(rowDimensions.length - 1) : [];

            headerRow.push(columnDimensions[colIndex]);

            colKeys.forEach((colKey) => {
                headerRow.push(colKey[colIndex] ?? emptyCell);
            });

            if (colIndex === 0) {
                headerRow.push('Totals');
            }

            csvRows.push(headerRow);
        }
    };

    const addRowHeader = () => {
        if (rowDimensions.length === 0) {
            return undefined;
        }

        const rowHeader =
            columnDimensions.length > 0
                ? [...rowDimensions, ...createEmptyCells(colKeys.length + 1)] // +1 for 'Totals' column
                : [...rowDimensions, 'Totals'];

        csvRows.push(rowHeader);
    };

    const addDataRows = () => {
        rowKeys.forEach((rowKey) => {
            const dataRow = [...rowKey];

            colKeys.forEach((colKey) => {
                const aggregator = pivotData.getAggregator(rowKey, colKey);

                dataRow.push(aggregator.format(aggregator.value()));
            });

            const rowTotal = pivotData.getAggregator(rowKey, []);

            dataRow.push(rowTotal.format(rowTotal.value()));

            csvRows.push(dataRow);
        });
    };

    const addFooterRow = () => {
        const footerRow = createEmptyCells(rowDimensions.length);

        if (rowDimensions.length > 0) {
            footerRow[rowDimensions.length - 1] = 'Totals';
        } else {
            footerRow.push('Totals');
        }

        colKeys.forEach((colKey) => {
            const colTotal = pivotData.getAggregator([], colKey);

            footerRow.push(colTotal.format(colTotal.value()));
        });

        const grandTotal = pivotData.getAggregator([], []);

        footerRow.push(grandTotal.format(grandTotal.value()));

        csvRows.push(footerRow);
    };

    addColumnHeaders();
    addRowHeader();
    addDataRows();
    addFooterRow();

    return csvRows.map((row) => row.join(',')).join('\n');
};
