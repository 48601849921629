import { Cross } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, Text } from '@mantine/core';
import { useResetAtom } from 'jotai/utils';

import { ActiveFilter } from 'state/sites/filters/utils/defineActiveFilter';

export const Pill = ({ label, stringifiedValue, atom }: ActiveFilter) => {
    const reset = useResetAtom(atom);

    return (
        <PillContainer>
            {!!label && (
                <Text c="gray.3" fw="400">
                    {label}:{' '}
                </Text>
            )}
            <Text c="gray.9" fw="300">
                {stringifiedValue}
            </Text>
            <ActionIcon size="xs" onClick={reset}>
                <Cross />
            </ActionIcon>
        </PillContainer>
    );
};

const PillContainer = styled.div({
    'display': 'flex',
    'fontSize': '0.875rem',
    'alignItems': 'center',
    'padding': `0.25rem 0.375rem 0.25rem 0.75rem`,
    'gap': '0.25rem',
    'position': 'relative',
    'height': '1.625rem',
    'width': 'max-content',
    'color': 'var(--color-grey-400)',
    'backgroundColor': 'var(--color-grey-50)',
    'borderRadius': '3rem',
    'borderColor': 'var(--color-purple-200)',
    '&:hover': {
        backgroundColor: 'var(--color-purple-100)',
        boxShadow: 'none',
    },
    '&:active': {
        outline: `0.1875rem solid var(--color-purple-200)`,
        backgroundColor: 'var(--color-grey-50)',
        boxShadow: 'none',
        outlineOffset: '-0.20rem',
    },
});
