import { useCallback, useEffect, useState } from 'react';

import { NumberInput, NumberInputProps } from '@mantine/core';

/**
 * A `NumberInput` component that automatically adjusts its width based on the text value.
 * @param {NumberInputProps} props - The props to pass to the NumberInput component.
 * @returns {JSX.Element} The CapacityInput component.
 */
const CapacityInput = ({ value, ...props }: NumberInputProps) => {
    const [inputWidth, setInputWidth] = useState('5.25rem');

    /**
     * Measures the width of the given text value in pixels.
     * @param {NumberInputProps['value']} text - The text to measure.
     * @returns {number} The width of the text in pixels.
     */
    const measureTextWidth = useCallback((text: NumberInputProps['value']) => {
        if (text === undefined || text === '') {
            return 0;
        }

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        context!.font = '14px roboto';

        return context!.measureText(String(text)).width;
    }, []);

    /**
     * Calculates the width of the input element based on the text value width in pixels.
     * Using width: max-content does not work because it does not take into account the width of the right section.
     */
    useEffect(() => {
        const rightSectionWidth = 34;
        const paddingAndBorder = 18;
        const textWidth = measureTextWidth(value);
        const totalWidth = textWidth + rightSectionWidth + paddingAndBorder;

        setInputWidth(`${Math.max(totalWidth, 50)}px`);
    }, [measureTextWidth, value]);

    return (
        <NumberInput
            {...props}
            value={value}
            styles={{
                root: { pointerEvents: 'none' },
                input: { width: inputWidth, textAlign: 'left' },
                rightSection: { padding: '0 1rem 0 0' },
            }}
        />
    );
};

export default CapacityInput;
