export const ROUTES = {
    auth: {
        index: '/auth',
        select_workspace: '/auth/select-workspace',
        reset_password: '/auth/reset-password',
        reset_password_success: '/auth/reset-password/success',
    },
    sites: {
        index: '/sites',
        inspection: {
            anomalies: 'anomalies',
            diagnostic: 'diagnostic',
            findings: 'findings',
            files: 'files',
            map: {
                index: 'map',
                module: 'module',
                anomaly: 'anomaly',
            },
        },
    },
    users: {
        index: '/users',
        invites: '/users/invites',
    },
};
