import { DropdownButton } from '@dronebase/shared-ui-core';
import styled from '@emotion/styled';
import { Popover, Checkbox, Stack, Group, Button, Title, Text, getStylesRef } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Table, VisibilityState } from '@tanstack/react-table';
import { some } from 'lodash';

import { TableAnomaly, defaultTabularTableColumnVisibilityState } from '../../tabularTableColumns';

const CustomizeColumns = ({
    table,
    resetToDefaultColumnVisibilityState,
    columnVisibilityState,
}: {
    table: Table<TableAnomaly>;
    resetToDefaultColumnVisibilityState: () => void;
    columnVisibilityState: VisibilityState;
}) => {
    const [opened, { close, toggle }] = useDisclosure(false);

    const visibilityHasChanged = some(
        defaultTabularTableColumnVisibilityState,
        (isVisible, key) => isVisible !== columnVisibilityState[key as keyof TableAnomaly],
    );

    return (
        <Popover opened={opened} onClose={close} position="bottom-start">
            <Popover.Target>
                <PurpleTextDropdownButton onClick={toggle} size="md">
                    Customize Columns
                </PurpleTextDropdownButton>
            </Popover.Target>
            <PopoverDropdown>
                <Title pb="1.5rem" order={6}>
                    Customize Columns
                </Title>
                <Stack spacing="0.75rem" miw="18rem">
                    {table
                        .getAllColumns()
                        .filter((column) => !column.getIsPinned())
                        .map((column) => (
                            <Checkbox
                                key={column.id}
                                label={<Text variant="body2light">{column.columnDef.header as string}</Text>}
                                checked={column.getIsVisible()}
                                disabled={!column.getCanHide()}
                                onChange={column.getToggleVisibilityHandler()}
                            />
                        ))}
                    <Group mt="md" position="left" align="baseline">
                        <Button
                            disabled={!visibilityHasChanged}
                            variant="text"
                            color="purple"
                            size="md"
                            styles={{
                                label: { lineHeight: '150%' },
                            }}
                            onClick={resetToDefaultColumnVisibilityState}
                        >
                            Default Settings
                        </Button>
                    </Group>
                </Stack>
            </PopoverDropdown>
        </Popover>
    );
};

export default CustomizeColumns;

const PurpleTextDropdownButton = styled(DropdownButton)({
    'color': 'var(--color-purple-400)',
    'boxShadow': 'none',
    'border': 'none',
    '&[aria-expanded="true"]': {
        [`& .${getStylesRef('rightIcon')}`]: {
            stroke: 'none',
        },
    },
});

const PopoverDropdown = styled(Popover.Dropdown)({
    border: 'none',
    boxShadow: '0px 4px 48px 0px rgba(141, 149, 162, 0.20)',
    borderRadius: '0.25rem',
    padding: '1.5rem',
});
