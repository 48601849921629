import { useMemo } from 'react';

import { ChevronLeft, Search as ZeitviewSearch } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { ActionIcon, Group, TextInput, RangeSlider, Checkbox, Button, Menu, Text, Flex } from '@mantine/core';
import { useAtom, useAtomValue } from 'jotai';

import GreyDropdownButton from 'components/buttons/GreyDropdownButton';
import CapacityInput from 'components/Inputs/CapacityInput';
import { ALL_TIME } from 'lib/constants';
import { getCookie } from 'lib/cookies';
import {
    searchFilterWithResetAtom,
    capacityRangeFilterWithResetAtom,
    inspectionYearFilterWithResetAtom,
} from 'state/sites/filters/siteFiltersStorage';
import { maxSiteCapacityAtom } from 'state/sites/sites';

interface SearchProps {
    handleToggling: () => void;
}

const Search = ({ handleToggling }: SearchProps) => {
    const maxCapacityRange = useAtomValue(maxSiteCapacityAtom);
    const [searchFilter, setSearchFilter] = useAtom(searchFilterWithResetAtom);
    const [capacityRangeFilter, setCapacityRangeFilter] = useAtom(capacityRangeFilterWithResetAtom);
    const [inspectionYearFilter, setInspectionYearFilter] = useAtom(inspectionYearFilterWithResetAtom);

    const INSPECTION_YEARS: string[] | null = getCookie('helio-inspectionYears')?.years;
    const CURRENT_YEAR = useMemo(() => new Date().getFullYear().toString(), []);

    return (
        <Flex direction="column" gap="1.0625rem" pr="1rem">
            <Group position="apart" align="center">
                <TextInput
                    icon={<ZeitviewSearch height="1.125rem" width="1.125rem" />}
                    placeholder="Search for sites by name"
                    w="21.875rem"
                    value={searchFilter}
                    onChange={(event) => {
                        setSearchFilter(event.currentTarget.value);
                    }}
                />
                <ActionIcon>
                    <ChevronLeft height="1.5rem" width="1.5rem" onClick={handleToggling} />
                </ActionIcon>
            </Group>

            <CapacityRangeContainer position="apart" align="center" noWrap>
                <Checkbox
                    checked={capacityRangeFilter.checked}
                    label="Only show sites within capacity range"
                    onChange={() => {
                        setCapacityRangeFilter({
                            ...capacityRangeFilter,
                            checked: !capacityRangeFilter.checked,
                        });
                    }}
                    styles={{ label: { pointerEvents: 'none', minWidth: 'max-content', fontSize: '0.875rem' } }}
                    w="max-content"
                />

                <Group align="center" position="right" spacing="0.5rem" noWrap w="100%" maw="30rem">
                    <Group position="right" w="8.5rem">
                        <CapacityInput value={capacityRangeFilter.low} rightSection="MW" />
                    </Group>

                    {maxCapacityRange && (
                        <RangeSlider
                            step={1}
                            showLabelOnHover={false}
                            value={[capacityRangeFilter.low, capacityRangeFilter.high]}
                            label={(value) => `${value} MW`}
                            disabled={!capacityRangeFilter.checked}
                            min={0}
                            max={maxCapacityRange}
                            onChange={(value) =>
                                setCapacityRangeFilter({
                                    ...capacityRangeFilter,
                                    low: value[0],
                                    high: value[1],
                                })
                            }
                        />
                    )}

                    <Group w="8.5rem" position="center">
                        <CapacityInput value={capacityRangeFilter.high} rightSection="MW" />
                    </Group>
                </Group>
            </CapacityRangeContainer>

            <Group align="center">
                <Text variant="body2">Inspections</Text>

                <Group spacing="sm">
                    <Button
                        variant={inspectionYearFilter === ALL_TIME ? 'filledGrey' : 'outlineGrey'}
                        onClick={() => setInspectionYearFilter(ALL_TIME)}
                        size="md"
                    >
                        All Time
                    </Button>

                    <Button
                        variant={inspectionYearFilter === CURRENT_YEAR ? 'filledGrey' : 'outlineGrey'}
                        onClick={() => setInspectionYearFilter(CURRENT_YEAR)}
                        size="md"
                    >
                        This Year
                    </Button>

                    <Menu>
                        <Menu.Target>
                            <GreyDropdownButton
                                variant={
                                    inspectionYearFilter && ![CURRENT_YEAR, ALL_TIME].includes(inspectionYearFilter)
                                        ? 'filledGrey'
                                        : 'outlineGrey'
                                }
                                size="md"
                            >
                                Custom
                            </GreyDropdownButton>
                        </Menu.Target>
                        <Menu.Dropdown>
                            {INSPECTION_YEARS?.map((year) => (
                                <Menu.Item key={year} onClick={() => setInspectionYearFilter(year)}>
                                    {year}
                                </Menu.Item>
                            ))}
                        </Menu.Dropdown>
                    </Menu>
                </Group>
            </Group>
        </Flex>
    );
};

export default Search;

const CapacityRangeContainer = styled(Group)({
    overflowX: 'auto',
    maxWidth: '100rem',
    padding: '0.25rem 0 0.25rem 0',
});
