import { useCallback, useEffect, useState } from 'react';

import {
    GoogleAuthProvider,
    OAuthProvider,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail,
    onAuthStateChanged,
    User,
} from 'firebase/auth';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';

import { clearCookies } from 'lib/cookies';
import auth from 'lib/firebase';
import { isInternalUser } from 'lib/helpers';

import { Config } from '../../config';

const MICROSOFT_PROVIDER = 'microsoft.com';
const LOGROCKET_APP_ID = String(Config.get('LOGROCKET_APP_ID'));

const useFirebaseAuth = () => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const initializeLogRocket = (user: User) => {
        if (user.email && !isInternalUser(user.email)) {
            LogRocket.init(LOGROCKET_APP_ID);
            LogRocket.identify(user.uid, {
                name: user.displayName ?? user.email,
                email: user.email,
            });
            setupLogRocketReact(LogRocket);
        }
    };

    const loginUser = async (email: string, password: string) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const { user } = userCredential;

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const loginWithGoogle = async () => {
        const googleProvider = new GoogleAuthProvider();

        try {
            const result = await signInWithPopup(auth, googleProvider);
            const { user } = result;

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const loginWithMicrosoft = async () => {
        const microsoftProvider = new OAuthProvider(MICROSOFT_PROVIDER);

        try {
            const result = await signInWithPopup(auth, microsoftProvider);
            const { user } = result;

            initializeLogRocket(user);
            setAuthUser(user);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    const logoutUser = useCallback(async () => {
        try {
            await signOut(auth);

            clearCookies();
            setAuthUser(null);
            setLoading(false);

            window.location.assign(`${window.location.origin}/auth`);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    }, [setAuthUser]);

    const resetPassword = async (email: string) => {
        try {
            await sendPasswordResetEmail(auth, email);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setError(error.message);

            throw error;
        }
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            setLoading(true);

            if (user) {
                initializeLogRocket(user);
                setAuthUser(user);
            } else {
                setAuthUser(null);
            }

            setLoading(false);
        });

        return () => unsubscribe();
    }, [setAuthUser]);

    return {
        authUser,
        loading,
        error,
        loginUser,
        logoutUser,
        resetPassword,
        loginWithMicrosoft,
        loginWithGoogle,
    };
};

export default useFirebaseAuth;
