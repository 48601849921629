import { useCallback, useEffect } from 'react';

// @ts-expect-error package has no types
import Chameleon from '@chamaeleonidae/chmln';
import { notifications } from '@dronebase/shared-ui-core';
import { User } from 'firebase/auth';
import { Outlet, useOutletContext } from 'react-router-dom';

import AppBar from 'components/Auth/AppBar';
import { Config } from 'config';
import { getCookie } from 'lib/cookies';
import { isInternalUser } from 'lib/helpers';
import { resetLocalStorage } from 'lib/helpers/localStorage';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';
import { useResetSiteFiltersStorage } from 'state/sites/filters/siteFiltersStorage';

import { AppContentContainer, AppLayoutContainer, FlexContainer } from './AppLayout.styled';
import LeftNavBar from './LeftNavBar';

const CHAMELEON_PROJECT = String(Config.get('REACT_APP_CHAMELEON_PROJECT'));
const ENVIRONMENT = String(Config.get('ENVIRONMENT'));

const AppLayout = () => {
    const authUser = useOutletContext() as User;
    const { logoutUser } = useFirebaseAuth();
    const resetAllSiteFilters = useResetSiteFiltersStorage();
    const workspace = getCookie('helio-workspace');

    const onLogout = useCallback(async () => {
        try {
            resetAllSiteFilters();
            resetLocalStorage();
            await logoutUser();
        } catch (error) {
            notifications.error({
                title: 'Something went wrong. Please try again later.',
                withCloseButton: true,
                withIcon: true,
            });
        }
    }, [logoutUser, resetAllSiteFilters]);

    useEffect(() => {
        if (authUser && workspace) {
            if (CHAMELEON_PROJECT) {
                Chameleon.identify(authUser.uid, {
                    name: authUser.displayName,
                    email: authUser.email,
                    appId: `solar-insights-${ENVIRONMENT}`,
                    id: authUser.uid,
                    isDroneBase: isInternalUser(authUser.email),
                    company: {
                        id: workspace.id,
                        name: workspace.name,
                    },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser.uid, workspace?.id]);

    return (
        <AppLayoutContainer>
            <AppBar onLogout={onLogout} />
            <FlexContainer>
                <LeftNavBar />
                <AppContentContainer>
                    <Outlet />
                </AppContentContainer>
            </FlexContainer>
        </AppLayoutContainer>
    );
};

export default AppLayout;
