import { ChangeEvent, FormEvent, useCallback, useState } from 'react';

import { Hide, Show } from '@dronebase/shared-ui-icons';
import styled from '@emotion/styled';
import { Box, Button, PasswordInput, Text, TextInput } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

import { LinkButton } from 'components/common/Buttons/LinkButton';
import { MIN_PASSWORD_LENGTH } from 'lib/constants';
import { ROUTES } from 'lib/constants/routes';
import { validateEmail, validatePassword, debounce } from 'lib/helpers';
import useFirebaseAuth from 'lib/hooks/useFirebaseAuth';

const LoginForm = () => {
    const { loading: isSubmitting, loginUser } = useFirebaseAuth();
    const navigate = useNavigate();

    const [formErrors, setFormErrors] = useState<{
        email: string | null;
        password: string | null;
        login: string | null;
    }>({
        email: null,
        password: null,
        login: null,
    });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSubmit = useCallback(
        async (e: FormEvent) => {
            e.preventDefault();

            try {
                await loginUser(email, password);

                navigate(ROUTES.auth.select_workspace);
            } catch (error) {
                setFormErrors((prev) => ({ ...prev, login: 'Invalid email address or password' }));
            }
        },
        [email, loginUser, navigate, password],
    );

    const setFormDebounceError = debounce((key: string, value: string) => {
        setFormErrors((prev) => ({
            ...prev,
            [key]: key === 'email' ? validateEmail(value) : validatePassword(value),
        }));
    }, 1000);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value, name } = e.target;

        name === 'email' ? setEmail(value) : setPassword(value);
        value && setFormDebounceError(name, value);
    };

    return (
        <StyledForm onSubmit={onSubmit}>
            <TextInput
                label="Email"
                aria-label="Email"
                type="email"
                name="email"
                autoComplete="email"
                value={email}
                error={formErrors.email}
                onChange={handleChange}
                onBlur={(e) => setFormDebounceError('email', e.target.value)}
                autoFocus={true}
            />

            <PasswordInput
                label="Password"
                aria-label="Password"
                visibilityToggleIcon={({ reveal }) => (reveal ? <Hide /> : <Show />)}
                name="password"
                value={password}
                error={formErrors.password}
                onChange={handleChange}
                onBlur={(e) => setFormDebounceError('password', e.target.value)}
                minLength={MIN_PASSWORD_LENGTH}
            />

            <Box style={{ display: 'flex', justifyContent: 'center' }}>
                <LinkButton size="lg" to={ROUTES.auth.reset_password}>
                    Forgot Password?
                </LinkButton>
            </Box>

            <Button
                type="submit"
                variant="filled"
                size="xl"
                disabled={isSubmitting || !!validateEmail(email) || !!validatePassword(password)}
            >
                Log In
            </Button>

            {formErrors.login && (
                <Text color="var(--color-severity-5)" ta="center" variant="caption1">
                    {formErrors.login}
                </Text>
            )}
        </StyledForm>
    );
};

export default LoginForm;

const StyledForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
});
