import { useCallback, useEffect, useRef, useState } from 'react';

import { Stack } from '@mantine/core';
import { Point, Position } from '@turf/turf';
import { useAtom, useAtomValue } from 'jotai';
import {
    AttributionControl,
    GeoJSONSource,
    Layer,
    LngLatBounds,
    Map as ReactMap,
    MapRef,
    MapLayerMouseEvent,
    NavigationControl,
    Source,
    ViewStateChangeEvent,
} from 'react-map-gl';
import { useNavigate } from 'react-router-dom';

import { MAPBOX_TOKEN, NON_SAT_MAP_STYLE } from 'lib/constants';
import { roundCoord } from 'lib/helpers';
import { mapBoundsFilterWithResetAtom } from 'state/sites/filters/siteFiltersStorage';
import { allSitesAtom } from 'state/sites/sites';

import { clusterCountLayer, clusterLayer, unclusteredPointLayer } from './layers';
import { SiteMarkerPopup } from './SiteMarkerPopup';
import { SiteMarkerInfo } from '../types';

const Map = () => {
    const sites = useAtomValue(allSitesAtom);
    const navigate = useNavigate();
    const mapRef = useRef<MapRef>(null);
    const [mapBounds, setMapBounds] = useAtom(mapBoundsFilterWithResetAtom);
    const [cursor, setCursor] = useState<string>('auto');
    const [siteGeo, setSiteGeo] = useState<GeoJSON.FeatureCollection | null>(null);
    const [hoverInfo, setHoverInfo] = useState<SiteMarkerInfo>(null);

    const handleZoom = (coords: number[]) => {
        mapRef.current?.fitBounds([
            [coords[2], coords[3]],
            [coords[0], coords[1]],
        ]);
    };

    useEffect(() => {
        if (sites.length) {
            const geoData: GeoJSON.Feature[] = [];
            const lonLat: Position[] = [];

            sites.forEach(({ location, siteName, uuid }) => {
                lonLat.push(location.coordinates);
                geoData.push({
                    type: 'Feature',
                    geometry: location,
                    properties: {
                        name: siteName,
                        uuid,
                    },
                });
            });

            setSiteGeo({
                type: 'FeatureCollection',
                features: geoData,
            });

            handleZoom(mapBounds);
        } else {
            setSiteGeo(null);
        }
    }, [mapBounds, sites]);

    const onMouseEnter = useCallback((event: any) => {
        const site = event.features?.[0];

        setCursor('pointer');

        if (!site?.properties.cluster) {
            setHoverInfo({
                longitude: (site.geometry as Point).coordinates[0],
                latitude: (site.geometry as Point).coordinates[1],
                siteName: site.properties.name,
            });
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        setCursor('auto');
        setHoverInfo(null);
    }, []);

    const handleRequestUpdate = useCallback(() => {
        const _coords: LngLatBounds | undefined = mapRef.current?.getBounds();
        const ne = _coords?.getNorthEast();
        const sw = _coords?.getSouthWest();

        if (ne && sw) {
            const _bounds = [roundCoord(ne.lng), roundCoord(ne.lat), roundCoord(sw.lng), roundCoord(sw.lat)];

            setMapBounds(_bounds);
        }
    }, [setMapBounds]);

    const handleMarkerClick = useCallback(
        (event: MapLayerMouseEvent) => {
            const feature = event.features?.[0];

            if (feature?.properties) {
                const { cluster, uuid } = feature.properties;

                if (cluster) {
                    const clusterId = feature.properties?.cluster_id;
                    const mapboxSource = mapRef.current?.getSource('sites') as GeoJSONSource;

                    mapboxSource.getClusterExpansionZoom(clusterId, (err, zoom) => {
                        if (err) {
                            return;
                        }

                        mapRef.current?.easeTo({
                            center: (feature?.geometry as Point).coordinates as [number, number],
                            zoom: zoom ?? 0 + 2,
                            duration: 800,
                        });

                        setTimeout(() => {
                            handleRequestUpdate();
                        }, 1000);
                    });
                }

                if (uuid) {
                    navigate(`/sites/${feature?.properties?.uuid}`);
                }
            }
        },
        [handleRequestUpdate, navigate],
    );

    const handleMapDragAndZoom = (e: ViewStateChangeEvent) => {
        if (e.originalEvent && e.originalEvent.type !== 'resize') {
            const ne = e.target.getBounds().getNorthEast();
            const sw = e.target.getBounds().getSouthWest();
            const bounds = [roundCoord(ne.lng), roundCoord(ne.lat), roundCoord(sw.lng), roundCoord(sw.lat)];

            setMapBounds(bounds);
        }
    };

    return (
        <Stack m="0 24px 24px" h="100%">
            <ReactMap
                reuseMaps
                initialViewState={{ bounds: mapBounds as [number, number, number, number] }}
                style={{ borderRadius: '10px' }}
                mapboxAccessToken={MAPBOX_TOKEN}
                mapStyle={NON_SAT_MAP_STYLE}
                onClick={handleMarkerClick}
                ref={mapRef}
                cursor={cursor}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onMoveEnd={handleMapDragAndZoom}
                // TODO: THIS IS CAUSING A BIGGER ISSUE WHERE YOU CANNOT DRAG THE MAP AROUND WITH THE MOUSE.
                // WE'LL BRING BACK THE MAP RESIZING/SCALING BUG ON COLLAPSE OF THE SIDEBAR AND HOPE TO ADDRESS IT SOON.
                // THIS WILL LIKELY BE FIXED BY SWTICHING FROM REACT-MAP-GL TO DECKGL
                // onRender={(event) => event.target.resize()}
                interactiveLayerIds={[clusterLayer.id, unclusteredPointLayer.id]}
                bearing={0}
                pitch={0}
                attributionControl={false}
                renderWorldCopies={false}
            >
                {siteGeo && (
                    <Source
                        id="sites"
                        type="geojson"
                        data={siteGeo}
                        cluster={true}
                        clusterMaxZoom={14}
                        clusterRadius={50}
                    >
                        <Layer {...clusterLayer} />
                        <Layer {...clusterCountLayer} />
                        <Layer {...unclusteredPointLayer} />
                    </Source>
                )}
                <SiteMarkerPopup siteInfo={hoverInfo} />

                <AttributionControl
                    compact={true}
                    style={{
                        backgroundColor: 'rgba(255,255,255,0.5)',
                        backdropFilter: 'blur(4px)',
                    }}
                />
                <NavigationControl showCompass={false} position="bottom-right" />
            </ReactMap>
        </Stack>
    );
};

export default Map;
