import { FilterFns, createColumnHelper } from '@tanstack/react-table';

import TdOverflowTooltip from 'components/common/Table/TdOverflowTooltip';

import PriorityBadge from './PriorityBadge';

export type TableAnomaly = {
    'Stack ID': string;
    'Position ID': string;
    'IR Signal': string;
    'RGB Signal': string;
    'Subsystem': string;
    'Priority': string;
    'Anomaly Status': string;
    'Energy Loss Weight': string;
    'Base Temperature': string;
    'Anomaly Temperature': string;
    'Temperature Delta': string;
    'Serial Number': string;
    'Latitude': string;
    'Longitude': string;
    'Combiner ID': string;
    'Inverter ID': string;
    'Power Loss (kW)': string;
};

export const defaultTabularTableColumnVisibilityState: Record<
    keyof Omit<TableAnomaly, 'Stack ID' | 'Position ID'>,
    boolean
> = {
    'IR Signal': true,
    'RGB Signal': true,
    'Subsystem': true,
    'Priority': true,
    'Anomaly Status': true,
    'Energy Loss Weight': true,
    'Base Temperature': true,
    'Anomaly Temperature': true,
    'Temperature Delta': true,
    'Serial Number': true,
    'Latitude': true,
    'Longitude': true,
    'Combiner ID': true,
    'Inverter ID': true,
    'Power Loss (kW)': true,
};

const columnHelper = createColumnHelper<TableAnomaly>();

export const defaultTabularColumns = [
    columnHelper.accessor('Stack ID', {
        header: 'Stack ID',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 100,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Position ID', {
        header: 'Position ID',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        enableSorting: false,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('IR Signal', {
        header: 'IR Signal',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('RGB Signal', {
        header: 'RGB Signal',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Subsystem', {
        header: 'Subsystem',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 120,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Priority', {
        header: 'Priority',
        cell: (info) => {
            const cellValue = info.getValue();
            const priorityNumber = cellValue?.split('').shift();

            return priorityNumber ? (
                <TdOverflowTooltip tooltipLabel={cellValue}>
                    <PriorityBadge priorityNumber={priorityNumber} />
                </TdOverflowTooltip>
            ) : (
                '-'
            );
        },
        size: 100,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Anomaly Status', {
        header: 'Anomaly Status',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Energy Loss Weight', {
        header: 'Energy Loss Weight',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 160,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Base Temperature', {
        header: 'Base Temperature',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 160,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Anomaly Temperature', {
        header: 'Anomaly Temperature',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 160,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Temperature Delta', {
        header: 'Temperature Delta',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 160,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Serial Number', {
        header: 'Serial Number',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Latitude', {
        header: 'Latitude',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 120,
        sortingFn: (a, b) => parseFloat(a.original.Latitude) - parseFloat(b.original.Latitude),
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Longitude', {
        header: 'Longitude',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 120,
        sortingFn: (a, b) => parseFloat(a.original.Longitude) - parseFloat(b.original.Longitude),
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Combiner ID', {
        header: 'Combiner ID',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 120,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Inverter ID', {
        header: 'Inverter ID',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 120,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
    columnHelper.accessor('Power Loss (kW)', {
        header: 'Power Loss (kW)',
        cell: (info) => {
            const cellValue = info.getValue();

            return <TdOverflowTooltip tooltipLabel={cellValue}>{cellValue}</TdOverflowTooltip>;
        },
        size: 150,
        filterFn: 'pivotTableFilterFn' as keyof FilterFns,
    }),
];
